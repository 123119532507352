.admin-box {
  width: 820px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.35);
  background-color: #242322;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.admin-box-title {
  font-family: NotoSansKR;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e0be7a;
}

.admin-box-search {
  width: 220px;
  height: 32px;
  padding: 6px 10px;
  margin: 1px 0 22px 81px;
  border-radius: 8px;
  border: solid 1px #ffffff;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  text-align: left;
  background: none;
}

.admin-box-tab {
  font-family: NotoSansKR;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  padding: 0;
  background: none;
  border: none;
  opacity: 0.4;
}

.admin-box-tab:disabled {
  opacity: 1;
}

.admin-box-b1 {
  width: 100%;
  height: 100%;
  padding: 6px;
  border-radius: 8px;
  background-color: #c2a76c;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1c1b1a;
  border: none;
}

.admin-box-b2 {
  width: 100%;
  height: 100%;
  padding: 6px;
  border-radius: 8px;
  border: solid 1px #c96161;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #c96161;
  background: none;
}

.admin-box-input {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border: solid 1px #ffffff;
  padding: 6px 10px;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  background: none;
}

.admin-box-h1 {
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}

.admin-box-row-h1 {
  font-family: NotoSansKR;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #e0be7a;
}

.admin-box-row-h2 {
  font-family: NotoSansKR;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}

.admin-box-row-h3 {
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}

.admin-box-row-h4 {
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #e0be7a;
  background: none;
  border: none;
  padding: 0;
  text-decoration: underline;
}

.admin-box-row-h5 {
  opacity: 0.6;
  font-family: NotoSansKR;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}

.admin-box-row-h6 {
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e0be7a;
  text-align: left;
}

.admin-box-row-h7 {
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #91bc6a;
}

.admin-box-row-h8 {
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #c96161;
}

.admin-box-row-b1 {
  width: 100%;
  height: 30px;
  padding: 7px;
  border-radius: 4px;
  border: solid 1px #e0be7a;
  font-family: NotoSansKR;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e0be7a;
  background-color: transparent;
}

.admin-box-row-b1:disabled {
  background-color: #e0be7a;
  color: #1c1b1a;
  cursor: default;
}

.admin-box-row-b2 {
  width: 100%;
  height: 30px;
  padding: 7px;
  border-radius: 4px;
  border: solid 1px #c96161;
  font-family: NotoSansKR;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #c96161;
  background-color: transparent;
}

.admin-box-row-b3 {
  width: 100%;
  height: 30px;
  padding: 7px;
  border-radius: 4px;
  border: none;
  font-family: NotoSansKR;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #1c1b1a;
  background-color: #e0be7a;
  cursor: default;
}

.admin-box-row-b4 {
  width: 100%;
  height: 30px;
  padding: 7px;
  border-radius: 4px;
  border: none;
  font-family: NotoSansKR;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  background-color: #91bc6a;
}

.admin-page {
  margin: 0 5px;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  background: none;
  border: none;
  padding: 0;
  color: #ffffff;
}

.admin-page:disabled {
  font-weight: bold;
  color: #e0be7a;
}

.admin-tooltip-h1 {
  font-family: NotoSansKR;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  text-align: left;
}

.admin-tooltip-h2 {
  font-family: NotoSansKR;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e0be7a;
  text-align: left;
}

.admin-tooltip-h3 {
  font-family: NotoSansKR;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  text-align: left;
}

.admin-edit-user-modal {
  width: 820px;
  height: 399px;
  margin: 172px auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.35);
  background-color: #242322;
  border-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.admin-edit-user-modal button.cancel {
  width: 54px;
  height: 32px;
  padding: 4px;
  border-radius: 8px;
  border: solid 1px #c96161;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #c96161;
  background: none;
}

.admin-edit-user-modal-h1 {
  font-family: NotoSansKR;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e0be7a;
  text-align: left;
}

.admin-edit-user-modal-h2 {
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  text-align: left;
}

.admin-edit-user-modal-input {
  padding: 6px 10px;
  opacity: 1;
  border-radius: 8px;
  border: solid 1px #e0be7a;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  text-align: left;
  background: none;
  width: 100%;
  height: 100%;
}

.admin-edit-user-modal-input:disabled {
  border: solid 1px #ffffff;
  opacity: 0.4;
}

.admin-dropdown {
  width: 80px;
  height: 32px;
  border-radius: 8px;
  border: solid 1px #e0be7a;
  background: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 10px;
  justify-content: space-between;
  position: absolute;
  left: 0;
  top: 0;
}

.admin-dropdown-img {
  width: 20px;
  height: 17px;
}

.admin-dropdown-label {
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e0be7a;
}

.admin-dropdown-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 4px 0 4px;
  border-color: #ffffff transparent transparent transparent;
}

.admin-dropdown-control {
  width: 120px;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.5);
  background-color: #242322;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 32px;
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.admin-dropdown-menu {
  width: 120px;
  height: 32px;
  padding: 0 10px;
  box-shadow: inset 0 -1px 0 0 #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background: none;
  border: none;
}

.admin-btn-cancel {
  width: 100%;
  height: 100%;
  padding: 12px;
  border-radius: 8px;
  border: solid 1px #c96161;
  background: none;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #c96161;
}

.admin-btn-confirm {
  width: 100%;
  height: 100%;
  padding: 10px;
  border-radius: 8px;
  background-color: #e0be7a;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #1c1b1a;
  border-color: transparent;
}

.private-toggle-on {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background-color: #e0be7a;
  padding: 0;
  border: none;
}

.private-toggle-off {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  border-color: #e0be7a;
  background-color: transparent;
  padding: 0;
  background: none;
  border-style: solid;
}

.private-label {
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}
