.Signin-main {
  margin: 0 auto;
  width: 940px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: auto;
  padding-top: 120px;
  padding-bottom: 180px;
}

.Signin-logoside {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 87.5px;
}

.Signin-logoside-logo {
  width: 283px;
  height: 283px;
  object-fit: contain;
  padding: 0;
}

.Signin-logoside-h1 {
  font-family: NotoSansKR;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.Signin-logoside-h2 {
  font-family: NotoSansKR;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.Signin-infoside {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.Signin-infoside-backbtn {
  width: 176px;
  height: 32px;
  padding: 5px 10px;
  border-radius: 8px;
  border: solid 1px #e0be7a;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e0be7a;
  background: none;
  text-align: center;
}

.Signin-infoside-box {
  width: 420px;
  height: auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.35);
  background-color: #242322;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}

.Signin-box-title {
  font-family: NotoSansKR;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e0be7a;
  text-align: left;
}

.Signin-info-label {
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  text-align: left;
}

.Signin-input-full {
  width: 380px;
  height: 32px;
  padding: 6px 10px 6px 10px;
  border-radius: 8px;
  border: solid 1px #ffffff;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  background: none;
}

.Signin-forget-area {
  height: 15px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.Signin-forget-label {
  font-family: NotoSansKR;
  font-size: 10px;
  font-weight: lighter;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  text-align: left;
}

.Signin-forget-button {
  font-family: NotoSansKR;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #e0be7a;
  text-align: right;
  text-decoration: underline;
  background: none;
  border: none;
}

.Signin-button {
  width: 380px;
  height: 44px;
  margin: 0 auto;
  padding: 12px 120px 12px 120px;
  border-radius: 8px;
  background-color: #e0be7a;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #1c1b1a;
  border: none;
}

.Signin-modal {
  margin: 160px auto 0;
  width: 420px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.35);
  background-color: #1c1b1a;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}

.Signin-modal-cancelbtn {
  width: 54px;
  height: 32px;
  padding: 4px;
  border-radius: 8px;
  border: solid 1px #c96161;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #c96161;
  background: none;
}

.Signin-modal-h1 {
  font-family: NotoSansKR;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #e0be7a;
}

.Signin-modal-h2 {
  font-family: NotoSansKR;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  text-align: left;
}

.Signin-modal-h3 {
  font-family: NotoSansKR;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e0be7a;
  text-align: left;
  text-decoration: underline;
  padding: 0;
  background: none;
  border: none;
}

.Signin-modal-h4 {
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  text-align: left;
}

.Signin-modal-input {
  width: 100%;
  height: 100%;
  padding: 6px 10px;
  border-radius: 8px;
  border: solid 1px #ffffff;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  background: none;
}

.Signin-modal-btn {
  width: 100%;
  height: 100%;
  padding: 6px;
  border-radius: 8px;
  background-color: #e0be7a;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #1c1b1a;
  border: none;
}
