.BusinessTree-btn {
  width: 176px;
  height: 32px;
  padding: 6px;
  border-radius: 8px;
  border: solid 1px #e0be7a;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e0be7a;
  background: none;
}

.BusinessTree-box {
  width: 1638px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.35);
  background-color: #242322;
}

.BusinessTree-box h1 {
  font-family: NotoSansKR;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e0be7a;
  text-align: left;
}

.BusinessTree-box h2 {
  font-family: NotoSansKR;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #aeaeae;
  text-align: left;
}

.BusinessTree-box-mask {
  width: 900px;
  height: 445px;
  opacity: 1;
  border-radius: 8px;
  box-shadow: inset 0 4px 8px 0 rgba(0, 0, 0, 0.35);
  background-color: #1c1b1a;
}

.BusinessTree-box-mask-total {
  position: absolute;
  top: 8px;
  left: 10px;
  width: 205px;
  height: 36px;
  padding: 12px;
  border-radius: 8px;
  border: solid 1px #e0be7a;
  background-color: #242322;
  font-family: NotoSansKR;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #e0be7a;
  z-index: 1;
}

.BusinessTree-box-mask-total-panel {
  width: 205px;
  height: 165px;
  padding: 10px;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.15);
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.BusinessTree-box-mask-total-panel h1 {
  font-family: NotoSansKR;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e0be7a;
  text-align: left;
}

.BusinessTree-box-mask-total-panel h2 {
  font-family: NotoSansKR;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  text-align: left;
}

.BusinessTree-box-mask-total-panel h3 {
  font-family: NotoSansKR;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #aeaeae;
  text-align: left;
}

.BusinessTree-box-container {
  width: auto;
  height: auto;
  background: none;
  padding: 20px;
}

.BusinessTree-member {
  width: 180px;
  height: 70px;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.35);
  background-color: #242322;
  display: flex;
  flex-direction: column;
  margin: 0px 10px;
}

.BusinessTree-member-title {
  display: flex;
  align-items: center;
}

.BusinessTree-member-title img {
  width: 36px;
  height: 18px;
  object-fit: contain;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.BusinessTree-member-title h1 {
  font-family: NotoSansKR;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #e0be7a;
}

.BusinessTree-member-title h2 {
  font-family: NotoSansKR;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #fff;
}

.BusinessTree-member-group {
  display: flex;
  justify-content: space-between;
}

.BusinessTree-member-group h1 {
  font-family: NotoSansKR;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #aeaeae;
}

.BusinessTree-member-group h2 {
  font-family: NotoSansKR;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: right;
  color: #e0be7a;
}
