.admin-business-box {
  width: 940px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.35);
  background-color: #242322;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.admin-business-box div.title {
  font-family: NotoSansKR;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e0be7a;
}

.admin-business-tooltip {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 205px;
  height: auto;
}

.admin-business-tooltip div.point {
  display: flex;
  justify-content: space-between;
}

.admin-business-tooltip div.point h1 {
  font-family: NotoSansKR;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e0be7a;
  text-align: left;
}

.admin-business-tooltip div.point h2 {
  font-family: NotoSansKR;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  text-align: right;
}

.admin-business-tooltip h1 {
  font-family: NotoSansKR;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #aeaeae;
  text-align: left;
}

.admin-incentives-box {
  width: 980px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.35);
  background-color: #242322;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.admin-incentives-box div.title {
  font-family: NotoSansKR;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e0be7a;
}

.dropdown-button {
  width: 380px;
  height: 32px;
  padding: 6px;
  border-radius: 8px;
  border: solid 1px #e0be7a;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  text-align: left;
  background-color: #242322;
}
.dropdown {
  position: relative;
  display: inline-block;
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #ffdab9;
  width: 380px;
  height: 170px;
  border-radius: 8px;
  background-color: transparent;
  border: solid 1px #e0be7a;
  padding: 5px 0;
}
.dropdown-content button {
  padding: 8px;
  text-decoration: none;
  display: block;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  text-align: left;
  width: 100%;
  height: 32px;
  background-color: #242322;
  align-items: center;
}
.dropdown-content button:hover {
  background-color: #cd853f;
}
.dropdown:hover .dropdown-content {
  display: block;
}
.dropdown:hover .dropdown-button {
  background-color: #cd853f;
}
