.App-footer {
  width: 940px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.Footer-logo {
  width: 60px;
  height: 60px;
}

.Footer-h1 {
  opacity: 0.4;
  font-family: NotoSansKR;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}

.Footer-h2 {
  opacity: 0.75;
  font-family: NotoSansKR;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}
