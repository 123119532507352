.App-header {
  width: 1180px;
  height: 76px;
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.Header-logo {
  width: 60px;
  height: 60px;
  margin: 8px 20px 0px 40px;
}

.Header-link {
  background-color: transparent;
  border: none;
  font-family: NotoSansKR;
  font-size: 18px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  position: relative;
  margin: 0 10px;
}

.Header-link-button {
  border: none;
  background-color: transparent;
  font-family: NotoSansKR;
  font-size: 18px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.Header-language {
  width: 100px;
  height: 56px;
  margin: 10px;
  padding: 11px 7px 10px 6px;
  border-radius: 8px;
  background-color: transparent;
  border-color: transparent;
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
}

.Header-language-selected {
  width: 100px;
  height: 56px;
  margin: 10px;
  padding: 11px 7px 10px 6px;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.35);
  border-color: transparent;
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
}

.Header-language-logo {
  width: 35px;
  height: 35px;
  margin: 0 10px 0 0;
  object-fit: contain;
}

.Header-language-current {
  width: 24px;
  height: 26px;
  margin: 4px 0 0 0;
  opacity: 0.7;
  font-family: NotoSansKR;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.Header-language-arrow {
  margin: 13px 0 14px 10px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 4px 0 4px;
  border-color: #ffffff transparent transparent transparent;
}

.Header-language-dropdown {
  width: 160px;
  height: 128px;
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.35);
  background-color: #242322;
  position: absolute;
  left: 0;
  top: 76px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  overflow: hidden;
  z-index: 1;
}

.Header-language-dropdown-title {
  height: 32px;
  margin: 0 14px;
  font-family: NotoSansKR;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #e0be7a;
  display: flex;
  align-items: center;
}

.Header-language-dropdown-menu {
  width: 160px;
  height: 32px;
  background-color: transparent;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Header-language-dropdown-menu:hover {
  background-color: #a88f5b;
}

.Header-language-dropdown-menu-selected {
  width: 160px;
  height: 32px;
  background-color: #e0be7a;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Header-language-dropdown-menu-label {
  font-family: NotoSansKR;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.Header-language-dropdown-menu-label-selected {
  font-family: NotoSansKR;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1c1b1a;
}
